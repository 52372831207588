import { graphql } from 'gatsby';
import { useStaticQuery } from 'gatsby';
import React from 'react';
import LinkText from '../../components/Blocks/LinkText/linkText';
import Paragraph from '../../components/Blocks/Paragraph/paragraph';
import Section from '../../components/Blocks/Section/section';
import HeroWithPhotoAndHeader from '../../components/HeroWithPhotoAndHeader/heroWithPhotoAndHeader';
import Layout from '../../components/layout';
import LinkBlock from '../../components/LinkBlock/linkBlock';
import SEO from '../../components/SEO/seo';

const InRainbowsAlbumPage = () => {
  const { site } = useStaticQuery(
        graphql`
          query {
            site {
              siteMetadata {
                siteUrl
              }
            }
          }
        `,
    );

  return (
    <Layout backto="/tools" noheader notopmargin >
        <SEO lang="ru" title={'Figma Covers'} keywords={['album', 'album review', 'Artyom Alekseev', 'Артём Алексеев', 'In Rainbows', 'Radiohead']} description="Почему In Rainbows считается одним из лучших альбомов Radiohead. Как я полюбил Radiohead и начал понимать их ценность.">
            <link rel="alternate" href={`${site.siteMetadata.siteUrl}/tools/fedro`} hrefLang="en" key="en"/>
        </SEO>
        <HeroWithPhotoAndHeader blHeader backto="/tools" desc="Improve your UI Design efficiency with Free Figma File Covers" imageURL={'covers.png'} heading="Figma File Covers"/>

        <Section heading="Source">
            <LinkBlock href="https://www.figma.com/community/file/960868711573803693" heading="The Figma Covers" para="Figma Community"/>
        </Section>

        <Section heading="Idea">
            <Paragraph>When you grow as a designer, you want to optimize every second of finding something. You want to give more time to the actual design, not to navigate into million of artboards.</Paragraph>
            <Paragraph>You can use these covers to find the needed design faster.</Paragraph>
        </Section>

    </Layout>
  ); };

export default InRainbowsAlbumPage;
